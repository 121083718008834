import React from "react";
import InfoPanel from "./InfoPanel";
import "./InfoPanel.css";
import "../CardEvent/CardEvent.css";
import InfoPanelData from "./InfoPanelData";


function InfoCardMain() {
  return (
    <div className="information_main">
      <p className="headingText">WHAT IS VIDMEDICAL?</p>
      <div className="informationShorts">
      {InfoPanelData.map((data)=>{return(<InfoPanel title={data.title} desc={data.desc} img={data.img}/>)})}
      </div>
    </div>
  );
}

export default InfoCardMain;
